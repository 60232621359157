<template>
  <b-overlay :show="showLoadingOverlay" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-spinner></b-spinner>
        <br>
        <p id="cancel-label">Validating token...</p>
      </div>
    </template>
    <div class="row justify-content-center">
    <div class="col-12">
      <h3 class="text-center tx-color-01 mg-b-5">Reset Password</h3>
      <p class="text-center tx-color-03 tx-16 mg-b-40">Please set your account password below</p>
      <form v-on:submit.prevent>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="inpPassword"
                     :class="{'text-danger': passwordError}"
              >Password</label>
              <div class="input-group">
              <input
                  id="inpPassword"
                  class="form-control"
                  :class="{'border-danger': passwordError}"
                  :type="passwordVisible ? 'text' : 'password'"
                  autocomplete="new-password"
                  v-model="password"
                  tabindex="2"
                  placeholder="Enter your account password"
              />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" @mousedown="passwordVisible=true" @mouseleave="passwordVisible=false" @mouseup="passwordVisible=false">
                    <eye-icon v-if="!passwordVisible"></eye-icon><eye-off-icon v-else></eye-off-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="inpPasswordRepeat"
                     :class="{'text-danger': passwordError}"
              >Repeat Password</label>
              <div class="input-group">
              <input
                  id="inpPasswordRepeat"
                  class="form-control"
                  :class="{'border-danger': passwordError}"
                  :type="passwordRepeatVisible ? 'text' : 'password'"
                  autocomplete="new-password"
                  v-model="passwordRepeat"
                  tabindex="3"
                  placeholder="Enter your account password again"
              />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" @mousedown="passwordRepeatVisible=true" @mouseleave="passwordRepeatVisible=false" @mouseup="passwordRepeatVisible=false">
                  <eye-icon v-if="!passwordRepeatVisible"></eye-icon><eye-off-icon v-else></eye-off-icon>
                </button>
              </div>
              </div>
              <span v-for="(error, index) in errors" :key="index">
                    <small class="text-danger">{{ error }}</small>
                    <br />
                  </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn btn-brand-02 btn-block"
                    @click="setPassword"
                    :disabled="loading"
                    tabindex="4">
              Set Password&nbsp;
              <span v-if="loading"><i class="fa fa-spinner fa-spin fa-2x"></i></span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  </b-overlay>
</template>
<script>
import { EyeIcon, EyeOffIcon } from 'vue-feather-icons';
import ApiService from "@/services/api.service";
import AuthService from "@/services/auth.service";
export default {
  name: 'ResetPassword',
  components: {
    EyeIcon,
    EyeOffIcon
  },
  data: () => ({
    loading: false,
    errors: [],
    password: '',
    passwordRepeat: '',
    passwordError: false,
    passwordVisible: false,
    passwordRepeatVisible: false,
    showLoadingOverlay: true,
  }),
  props: {
    userId: {
      type: String,
      required: true
    },
    code: {
      type: String,
      required: true
    }
  },
  async created() {
    await this.validatePasswordResetCode();
    this.showLoadingOverlay = false;
  },
  methods: {
    validateInputs() {
      // Clear error text
      this.errors = [];
      this.passwordError = false;

      if (this.password.length === 0 || this.passwordRepeat.length === 0) {
        this.errors = ['Please enter your account password'];
        this.passwordError = true;
        return false;
      }

      if (this.password !== this.passwordRepeat) {
        this.errors = ['Please retype your account password, as they do not match'];
        this.passwordError = true;
        return false;
      }

      return true;
    },
    async setPassword() {
      this.loading = true;
      if (!this.validateInputs()) {
        this.loading = false;
        return;
      }

      try {
        let { data } = await ApiService.post(
            'resetPassword',
            {'user': this.userId, 'code': this.code, 'newPassword': this.password}
        );

        if (data === undefined) {
          this.errors = ["An unexpected error occurred while trying to set your account password, please try again later"];
          this.loading = false;
          return;
        } else if (data.success) {
          await this.$store.dispatch('login', {email: data.user.email, password: this.password});
          let toRoute = "Inquiries";
          if (this.$store.getters.passwordExpired) {
            toRoute = "Password Expired";
          }
          await this.$router.push({ name: toRoute });

          // Do we need the user object returned?
          // login
          // doAfterLogin
        } else {
          this.errors = data.errors;
        }
        this.loading = false;
      } catch (error) {
        console.log("error resetting password!");
        console.log(error);
        // this.errors = error.response.data.errors.newPassword;
        this.loading = false;
      }
    },
    async validatePasswordResetCode() {
      const isUserAbleToResetPasswordWithCode = await AuthService.validatePasswordResetCode(this.userId, this.code);

      if(isUserAbleToResetPasswordWithCode) {
        await ApiService.post(
            'validatePasswordResetCode',
            { 'user': this.userId, 'code': this.code }
        );
      } else {
        if (!this.$store.getters.isLoggedIn) {
          await this.$router.push('/login').then(() => {
            this.$bvToast.toast(
                "This link has expired, please check your email for the latest password reset message and try again from there.",
                {title: "Invalid Password Reset Code", variant: 'danger', noAutoHide: true}
            );
          });
        }
      }
    }
  },
}
</script>
