<template>
  <div class="row justify-content-center">
    <div class="col-7">
      <card>
        <template v-slot:header>
          <h2>{{ title }}</h2>
          <em>Please choose a new password for <strong>{{ $store.getters.user.email }}</strong></em>
        </template>
          <form v-on:submit.prevent>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="inpCurrentPassword"
                         :class="{'text-danger': currentPasswordError}"
                  >Current Password</label>
                  <input type="email"
                         :value="$store.getters.user.email"
                         autocomplete="username email"
                         style="display: none;" />
                  <div class="input-group">
                    <input :type="currentPasswordVisible ? 'text' : 'password'"
                           id="inpCurrentPassword"
                           class="form-control"
                           :class="{'border-danger': currentPasswordError}"
                           placeholder="Enter your current password"
                           v-model="currentPassword"
                           autocomplete="current-password"
                           ref="password"
                           tabindex="1"
                    >
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary"
                              type="button"
                              @mousedown="currentPasswordVisible=true"
                              @mouseleave="currentPasswordVisible=false"
                              @mouseup="currentPasswordVisible=false">
                        <eye-icon v-if="!currentPasswordVisible"></eye-icon><eye-off-icon v-else></eye-off-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="inpNewPassword"
                         :class="{'text-danger': newPasswordError}"
                  >New Password</label>
                  <div class="input-group">
                    <input :type="newPasswordVisible ? 'text' : 'password'"
                           id="inpNewPassword"
                           class="form-control"
                           :class="{'border-danger': newPasswordError}"
                           placeholder="Enter your new password"
                           v-model="newPassword"
                           autocomplete="new-password"
                           ref="password"
                           tabindex="2"
                    >
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary"
                              type="button"
                              @mousedown="newPasswordVisible=true"
                              @mouseleave="newPasswordVisible=false"
                              @mouseup="newPasswordVisible=false">
                        <eye-icon v-if="!newPasswordVisible"></eye-icon><eye-off-icon v-else></eye-off-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="inpNewPasswordRepeat"
                         :class="{'text-danger': newPasswordError}"
                  >Repeat New Password</label>
                  <div class="input-group">
                    <input :type="newPasswordRepeatVisible ? 'text' : 'password'"
                           id="inpNewPasswordRepeat"
                           class="form-control"
                           :class="{'border-danger': newPasswordError}"
                           placeholder="Enter your new password again"
                           v-model="newPasswordRepeat"
                           autocomplete="new-password"
                           ref="password"
                           tabindex="2"
                    >
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary"
                              type="button"
                              @mousedown="newPasswordRepeatVisible=true"
                              @mouseleave="newPasswordRepeatVisible=false"
                              @mouseup="newPasswordRepeatVisible=false">
                        <eye-icon v-if="!newPasswordRepeatVisible"></eye-icon><eye-off-icon v-else></eye-off-icon>
                      </button>
                    </div>
                  </div>
                  <span v-for="(error, index) in errors" :key="index">
                    <small class="text-danger">{{ error }}</small>
                    <br />
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="btn btn-brand-02 btn-block"
                        @click="updatePassword"
                        :disabled="loading"
                        tabindex="4">
                  Update Password&nbsp;
                  <span v-if="loading"><i class="fa fa-spinner fa-spin fa-2x"></i></span>
                </button>
              </div>
            </div>
          </form>
      </card>
    </div>
  </div>
</template>
<script>
import {EyeIcon, EyeOffIcon } from 'vue-feather-icons';
export default {
  name: 'passwordExpired',
  components: {
    EyeIcon,
    EyeOffIcon
  },
  data: () => ({
    title: 'Change Password',
    loading: false,
    errors: [],
    currentPassword: '',
    currentPasswordError: false,
    currentPasswordVisible: false,
    newPassword: '',
    newPasswordRepeat: '',
    newPasswordError: false,
    newPasswordVisible: false,
    newPasswordRepeatVisible: false,
  }),
  created() {
    this.title = this.$route.meta.title;
  },
  methods: {
    validateInputs() {
      // Clear error text
      this.errors = [];
      this.currentPasswordError = false;
      this.newPasswordError = false;

      if (this.currentPassword.length === 0) {
        this.errors = ['Current Password is required to update your password'];
        this.currentPasswordError = true;
        return false;
      }
      if (this.newPassword.length === 0 || this.newPasswordRepeat.length === 0) {
        this.errors = ['Please enter your new password'];
        this.newPasswordError = true;
        return false;
      }

      if (this.newPassword !== this.newPasswordRepeat) {
        this.errors = ['Please retype your new password, as they do not match'];
        this.newPasswordError = true;
        return false;
      }

      return true;
    },
    async updatePassword() {
      this.loading = true;
      if (!this.validateInputs()) {
        this.loading = false;
        return;
      }
      const data = await this.$store
          .dispatch('updatePassword', {'currentPassword': this.currentPassword, 'newPassword': this.newPassword});
      if (data === undefined) {
        this.errors = ["An unexpected error occurred while trying to update your password, please try again later"];
        this.loading = false;
        return;
      } else if (data.success) {
        let toRoute = 'New Inquiry';
        if (this.$store.getters.clients.length === 1) {
          toRoute = 'Dashboard';
        }
        this.$router.push({ name: toRoute });
        this.$bvToast.toast(
            'Successfully update your password',
            {title: 'Password Expiry', variant: 'success'}
        );
      } else {
        this.errors = data.errors;
      }
      this.loading = false;
    },
  },
  mounted() {
  }
}
</script>
<style scoped>
.row > .col > div.client-col {
  border: 1px solid #cad1d7;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
div.client-col:hover {
  background-color: #cad1d7;
}
</style>
