<template>
  <div class="row w-100">
    <div>
      <top-navbar></top-navbar>
      <div class="row justify-content-center">

        <div class="col-7">
          <card class="my-5">
            <template v-slot:header>
              <h2>Terms of Use</h2>

              <div v-if="!userHasAcceptedTerms">
                <em>News users must accept the Terms of Use before proceeding.</em><br>
                <br>
                <em>Scroll down to accept them.</em>
              </div>
            </template>

            <div id="termsContainer">
              <p>In these Terms of Use, first person terms (“we”, “us”, “our”, “ours”) mean iCede Solutions
                Ltd.
                Second person terms (“you”, “your”, “yours”, “yourself”) mean you, as a user of this website
                and of the iCede System (known as “the System”).
                When you are given access to the System, you are deemed to accept these Terms of Use
                and you will be required to tick a box on the System website to confirm your acceptance.</p>
              <h4>1 RULES RELATING TO ACCESS TO AND USE OF THE SYSTEM.</h4>
              <blockquote><small>
                <p>1.1 Your User ID and password are for your sole use as an individual and must not be
                  shared.</p>
                <p>1.2 Passwords must meet a minimum standard of security – which is shown when you
                  first log into the System. Passwords must not be written down or shared.</p>
                <p>1.3 Your password can be reset via the System Log-in Page.</p>
                <p>1.4 Your account will be locked if you incorrectly input your password 3 times when
                  attempting to log into the System.</p>
                <p>1.5 If for any reason either you suspect that any username or password has been
                  disclosed to or obtained by another party, you should contact us immediately. We
                  never contact users requesting them to confirm their password or other personal
                  details.</p>
                <p>2 Any Personal Information recorded in the System is owned and controlled by the
                  Controlling Office and we will act
                  in accordance with
                  the Controlling Office’s
                  instructions
                  in relation to management of such data, providing such instructions are compliant
                  with
                  the Data Protection Act 1998.</p>
                <p>3 You must ensure that any Personal Information that you use or download from the
                  System is managed in accordance with
                  the Controlling Office’s Privacy
                  Policy and with
                  any legal or regulatory requirements of the territory in which you operate.</p>
              </small></blockquote>
              <h4>4 SPECIFIC PROHIBITIONS</h4>
              <blockquote><small>
                <p>You agree not to do, or attempt to do, any of the following acts:</p>
                <p>4.1 Abuse, harass, threaten, stalk, defame or in any way seek to violate the rights
                  of
                  another user or third party.</p>

                <p>4.2 Publish or seek to distribute any information that is unlawful, harmful, obscene,
                  indecent, libellous, profane, defamatory, racist, or in any other way inappropriate
                  or
                  objectionable.</p>
                <p>4.3 Use or harvest data provided by other Users in a way that they would object
                  to.</p>
                <p>4.4 Encourage illegal activity or activity that violates the rights of other users or
                  third
                  parties, whether individuals or organisations.</p>
                <p>4.5 Pose as another User, third party or employee for the purposes of obtaining User
                  or
                  third party information.</p>
                <p>4.6 Transmit or transfer any viruses, trojans, worms or any other malicious programs
                  or
                  code intended to spy on, gain control over, disrupt, destroy or in any other way
                  impair any computer hardware or software or any other equipment.</p>
                <p>4.7 Gain access to our System, its servers or other equipment in order to: either (a)
                  disrupt, impair, overload or otherwise hinder or compromise the safety, security or
                  privacy of any part of the System provided by or relied upon by us and Users; or (b)
                  examine, replicate, or otherwise copy the code of the website or databases provided
                  by us.</p>
                <p>4.8 Reframe or repurpose the System or any content on it, remove, obscure or block
                  any
                  notices provided by us on the System.</p>
                <p>4.9 Load or provide access to content on the system or link to other content from the
                  System, which infringes the trademark, patent, trade secret or any other proprietary
                  right of a third party or infringes any intellectual property law.</p>
                <p>4.10 Use any robot, spider, scraper or other technical means to access the System or
                  any
                  content on the System.</p>
                <p>4.11 We reserve the right to remove (with or without notice) any Content which in our
                  sole judgment is in breach of this Schedule.</p>
                <p>4.12 You must not attempt to conduct tasks or actions within the System that you are
                  not
                  permitted or trained to do.</p></small></blockquote>
              <h4>5 DISCLAIMER</h4>
              <blockquote><small>
                <p>5.1 Access to the System is given under your or your company’s agreement with your
                  Controlling Office and the Terms
                  of Use contained in this Schedule. The service you
                  receive under the System is governed by that agreement and these Terms of Use.
                  You do not have a direct contract with us.</p>

                <p>5.2 Any questions related to the accuracy of data in the System must be addressed to
                  your Controlling Office. We are
                  not responsible for the data, which is inputted by
                  users. We are also not liable for errors in the System and disclaim all liability to
                  you
                  if any errors occur.</p></small></blockquote>
              <h4>6 CONTACT US</h4>
              <blockquote><small>
                <p>6.1 If you have any questions or concerns related to the Terms of Use, please contact
                  the Controlling Office in the
                  first instance.</p>
                <p>6.2 To contact us directly at iCede Solutions Ltd our details are: Suite 616A Cotton
                  Exchange,
                  Bixteth Street, Liverpool, L3 9LQ. United Kingdom or email <span class="txtEmails"></span>
                </p></small></blockquote>
              <h4>7 ACCEPTANCE OF TERMS OF USE</h4>
              <blockquote><small>
                <p>7.1 By using the System you accept that you must comply with the Terms of Use.</p>
                <p>7.2 Any breach of the Terms of Use will be reported to
                  the Controlling Office and may
                  lead to the suspension or termination of your access to the System</p>
              </small></blockquote>
            </div>

            <form v-on:submit.prevent v-if="!userHasAcceptedTerms">
              <div class="row">
                <div class="col">
                  <button class="btn btn-success"
                          @click="accept"
                          tabindex="4">
                    <unlock-icon></unlock-icon>&nbsp;
                    Accept&nbsp;
                    <span v-if="loading"><i class="fa fa-spinner fa-spin fa-2x"></i></span>
                  </button>
                  <button class="btn btn-danger"
                          @click="reject"
                          tabindex="4">
                    <unlock-icon></unlock-icon>&nbsp;
                    Reject&nbsp;
                  </button>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style scoped>
button {
  margin: 0 10px 20px 0;
}
.card {
  margin-bottom: 60px;
}

</style>
<script>

import "bootstrap-vue/dist/bootstrap-vue.css";
import "../../assets/css/main.css";
import "../../assets/sass/main.scss";
import "../../assets/sass/light-bootstrap-dashboard.scss";

import {mapGetters} from 'vuex';
import {UnlockIcon} from 'vue-feather-icons';
import TopNavbar from "@/views/TopNavbar";
import ContentFooter from "@/views/ContentFooter.vue";
import Card from "@/components/Cards/Card";

export default {
  name: 'TwoFactor',
  components: {ContentFooter, TopNavbar, UnlockIcon, Card },
  data: () => ({
    loading: false,
    error: '',
    oneTimePassword: '',
    oneTimePasswordHasError: false,
    twoFactorMethod: 'Email',
    codeSent: false,
    sendingCode: false,
    mobileNumber: '',
    usingMobileMethod: false,
  }),
  computed: {
    ...mapGetters([
      'twoFactorSetup',
      'twoFactorAuthNeeded',
    ]),
    userHasAcceptedTerms() {
      return this.$store.getters.termsAccepted;
    }
  },
  methods: {
    async reject() {
      this.$store.dispatch('logout');
      this.$router.push({name: 'Login'});
    },
    async accept() {
      this.loading = true;
      const data = await this.$store.dispatch('acceptTerms', {});
      if (data.success) {
          // TODO: Maybe go to the page they were trying to get to before they were redirected to 2FA
          let toRoute = 'New Inquiry';
          // if (this.$store.getters.clients.length === 1) {
          //   toRoute = 'Dashboard';
          // }
          this.$router.push({name: toRoute});
      }
      // this.$store.commit(SET_TWO_FACTOR_METHOD, {method: this.twoFactorMethod});
      this.loading = false;
    },
  },
}
</script>
